.app {
  background-color: #f7f7f7;
}
section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}
.container {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 550px;
  position: absolute;
  padding: 20px;
}
.main-text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  text-align: left;

  font-size: 25px;
}

.red-text {
  color: #ea1538;
}
.click-here {
  color: #ea1538;
}
.more {
  margin-top: 30px;
  color: white;
}
.click-here:hover {
  color: white;
  transition: 0.3s;
}

.card-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 70px;
  flex-direction: column;
}

.content-section {
  margin-top: 100px;
  flex-wrap: wrap;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content-section .card {
  width: 23%;
  min-height: 400px;
  border-radius: 20px;
  margin: 40px 25px -30px 25px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
  transition: 0.4s;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: scale(1.15);
}

.content-section .card img {
  object-fit: cover;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 200px;
}

.quality {
  height: 225px !important;
}

.grivitsa {
  height: 250px !important;
}

.content-section .card h2 {
  margin: 16px 0px;
  font-size: 28px;
  color: #ea1538;
}

.content-section .card p {
  font-weight: 600;
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  margin: 5px 0;
  overflow: hidden;
  border-radius: 20px;
}

.title {
  color: #ea1538;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0px 0px 0px;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.title::before,
.title::after {
  width: 100%;
  content: "";
  height: 2px;
  background-color: #ea1538;
  display: block;
}
.title::before {
  margin-right: 18px !important;
}

.title::after {
  margin-left: 18px !important;
}

.content-section.second {
  margin-bottom: 100px;
}

@media screen and (768px <= width <= 1800px) {
  .content-section .card {
    height: 400px;
  }
}

@media screen and (max-width: 896px) {
  .content-section {
    flex-direction: column;
    margin-top: 20px;
  }
  .content-section.second {
    margin: 0 auto !important;
  }
  .content-section .card {
    height:auto;
    width: 90%;
    margin: 15% auto !important;
  }
}

@media screen and (max-width: 590px) {
  .main-text {
    font-size: 1.25em;
  }
  .container {
    width: 350px;
  }
  .title {
    font-size: 1.35em;
  }
}
