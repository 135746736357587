* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
}

.hero {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("https://i.postimg.cc/zXPx1Ftr/banner.jpg");
  background-size: cover;
  background-position: center;
  overflow-y: hidden;
  overflow-x: hidden;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-left: 5%;
  padding-right: 3%;
  margin-left: 0%;
}

.logo {
  width: 100px;
}

span {
  color: #ea1538;
}
nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 10px 20px;
}

ul.black li a {
  color: black;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  position: relative;
  letter-spacing: 0.5px;
  padding: o 10px;
}

nav ul li a:after {
  content: "";
  position: absolute;
  background-color: #ea1538;
  height: 3px;
  width: 0;
  left: 0;
  bottom: -10px;
  transition: 0.5s;
}

nav ul li a:hover {
  color: #ea1538;
}

nav ul li a:hover:after {
  width: 100%;
}
nav ul li a.selected {
  color: #ea1538;
}
nav ul li a.selected:after {
  content: "";
  position: absolute;
  background-color: #ea1538;
  color: #ea1538;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: -10px;
  transition: 0;
}

.red-button {
  border: none;
  background: #ea1538;
  padding: 12px 30px;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: 15px;
  transition: 0.4s;
  margin-left: -14px;
}

.red-button:hover {
  transform: scale(1.3);
  cursor: pointer;
}

ion-icon#white {
  font-size: 32px;
  color: white;
}

ion-icon#black {
  font-size: 32px;
  color: black;
}

ion-icon#black:hover {
  font-size: 32px;
  transition: color 0.5s;
  color: #ea1538;
}

ion-icon#white:hover {
  font-size: 32px;
  transition: color 0.5s;
  color: #ea1538;
}

.social-link {
  margin-right: 7%;
}

.social-links-container {
  width: 180px;
}

.copy-message-content {
  position: absolute;
  right: 44%;
  top: 16.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}

.copy-message-content span {
  font-size: 18px;
  z-index: 1;
  color: #ffffff;
  padding: 5px 10px;
  background-color: rgba(35, 30, 30, 0.795);
  border-radius: 5px;
}

.more {
  display: inline-block;
  text-decoration: none;
  margin-left: 5%;
}

.footer {
  background-color: #ea1538;
  color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  font-weight: 750;
  bottom: auto;
  width: 100%;
}

.contact-footer {
  bottom: 0 !important;
}

.footer__contact {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.footer__item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.footer__icon {
  margin-right: 8px;
}

.footer__link {
  color: #ffffff;
  text-decoration: none;
}

.footer__social {
  display: flex;
  justify-content: center;
}

.footer__social-link {
  color: #ffffff;
  font-size: 20px;
  margin: 0 10px;
}
.more-info {
  font-weight: 750;
  margin: 20px auto 30px auto;
}
@media (max-width: 1200px) {
  nav {
    padding-left: 5%;
    padding-right: 5%;
  }
  ion-icon {
    font-size: 24px;
  }
  .logo {
    width: 80px;
  }

  nav ul li a {
    font-size: 16px;
  }

  .number {
    font-size: 14px;
  }
  .number:hover {
    transform: scale(1.15) !important;
  }
  .card p {
    font-size: 14px !important;
  }
  .card h2 {
    font-size: 22px !important;
  }
}
@media (min-width: 1028px) and (max-width: 1082px) {
  .number {
    padding: 11px 28px;
    font-size: 12px;
  }
  ion-icon {
    font-size: 26px !important;
  }
  nav ul li a {
    font-size: 13px;
  }
  .logo {
    width: 70px;
  }
}
@media (max-width: 1030px) {
  nav {
    padding-left: 4%;
    padding-right: 4%;
  }
  ion-icon {
    font-size: 22px !important;
  }
  nav ul li {
    margin: 0 -10px;
  }

  nav ul li a {
    font-size: 14px;
  }

  .number {
    font-size: 14px;
  }
  .number:hover {
    transform: scale(1.15) !important;
  }
  .card p {
    font-size: 14px !important;
  }
  .card h2 {
    font-size: 22px !important;
  }
}
@media (max-width: 952px) {
  .number:hover {
    transform: scale(1.3) !important;
  }
  .card p {
    font-size: 14px !important;
  }
  .card h2 {
    font-size: 22px !important;
  }
}

@media (max-width: 860px) {
  .number {
    font-size: 15px;
  }
  .number:hover {
    transform: scale(1.15) !important;
  }
  .card p {
    font-size: 11px !important;
  }
  .card h2 {
    font-size: 19px !important;
  }
}

@media (max-width: 768px) {
  nav {
    padding-left: 3%;
    padding-right: 3%;
    transform: scale(0.8);
  }

  nav ul li {
    margin: 0 2px;
  }

  nav ul li a {
    font-size: 12px;
  }

  .number {
    font-size: 10px;
  }
  .footer__contact {
    flex-direction: column;
  }

  .footer__item {
    margin-bottom: 10px;
  }

  .footer__social {
    margin-top: 20px;
  }
}

.nav-btn {
  padding: 3px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
  font-size: 2.8rem;
}

@media only screen and (max-width: 965px) {
  ul.black li a {
    color: white;
  }
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  ion-icon {
    font-size: 55px !important;
  }
  ion-icon#black {
    font-size: 32px;
    color: white;
  }
  .black.nav-btn {
    color: black;
  }
  .white.nav-btn {
    color: white;
  }
  .logo {
    margin: 0 auto;
    width: 167px;
  }
  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .red-button.number {
    margin: 0 auto !important;
    font-size: larger;
    padding: 20px 50px;
  }
  .social-links-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
  nav {
    position: fixed;
    z-index: 999;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgb(85, 136, 153);
    transition: 1s;
  }

  .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    color: #ffffff;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav ul li a {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 750px) {
  ul.black li a {
    color: white;
  }
  .nav-btn {
    visibility: visible;
    opacity: 1;
  }
  ion-icon {
    font-size: 35px !important;
  }
  ion-icon#black {
    font-size: 32px;
    color: white;
  }
  .black.nav-btn {
    color: black;
  }
  .logo {
    margin: 0 auto;
    width: 115px;
  }
  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .red-button.number {
    margin: 0 auto !important;
    font-size: medium;
  }
  .social-links-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
  }
  nav {
    position: fixed;
    z-index: 999;
    top: -100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgb(85, 136, 153);
    transition: 1s;
  }

  .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    color: #ffffff;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav ul li a {
    font-size: 1.8rem;
  }
}
