.image-gallery {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3.5%;
}

.gallery-item {
  position: relative;
  width: 280px;
  height: 280px;
  margin: 15px;
  border-radius: 20px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
  object-fit: cover;
}

/* Show overlay on hover for desktop */
@media (hover: hover) {
  .gallery-item:hover img {
    transform: scale(1.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .gallery-item:hover .gallery-overlay {
    opacity: 1;
    pointer-events: auto;
  }
}

/* Show overlay on click for mobile */
.gallery-item.active .gallery-overlay {
  opacity: 1;
  pointer-events: auto;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  border-radius: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.209);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.05s;
}

.gallery-overlay p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}

.gallery-overlay a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}
