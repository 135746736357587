.card-container.sessions {
  justify-content: flex-start !important ;
}

.title.title-sessions {
  margin-top: 0%;
  background-color: white;
}

.yt-video-container {
  display: flex;
  justify-content: center;
}
#yt-video {
  width: 420px;
  height: 215px;
  margin: 50px auto;
}

p.session-paragraph {
  font-weight: 600;
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  margin: 5px 0;
  overflow: hidden;
  border-radius: 20px;
  text-align: center;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
h2.sessions {
  text-align: center;
  color: #000000;
  margin-top: 3%;
  font-size: 22px !important;
}

h2.photos-label {
  text-align: center;
  color: #ea1538;
}

div.border-container {
  height: 570px;
  --b: 0.5em;
  --c: 3em;
  --r: 2em;
  position: relative;
  margin: 3% auto;
  border: solid var(--b) transparent;
  padding: 1.5em;
  padding-bottom: 5em;
  max-width: 23em;
  font: 1.25em "Josefin Sans", sans-serif;
}
div.border-container::before {
  margin: 3% auto;
  position: absolute;
  z-index: -1;
  inset: calc(-1 * var(--b));
  border: inherit;
  border-radius: var(--r);
  background: linear-gradient(rgb(27, 26, 26), #ea1538, rgb(25, 24, 24))
    border-box;
  --corner: conic-gradient(from -90deg at var(--c) var(--c), red 25%, #000 0 0)
    0 0 / calc(100% - var(--c)) calc(100% - var(--c)) border-box;
  --inner: conic-gradient(red 0 0) padding-box;
  -webkit-mask: var(--corner), var(--inner);
  -webkit-mask-composite: source-out;
  mask: var(--corner) subtract, var(--inner);
  content: "";
}

.title-sessions-2 {
  margin-top: 4% !important;
}
.contact-link {
  text-decoration: none !important;
}

.label-address,
.address {
  text-decoration: none !important;
  color: initial;
}
.tylenovo-paragraph {
  font-size: 18px;
}

article {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

article h2 {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

article p {
  margin-bottom: 1rem;
}

.red-button.show-more {
  text-decoration: none !important;
  margin: 0px auto 0px !important;
}

.show-more-wrap {
  margin: 50px auto 0px !important;
}

.card-session {
  margin-top: 2.5% !important;
  margin-bottom: 3% !important;
  height: 500px !important;
}

@media screen and (max-width: 510px) {
  h2.sessions {
    font-size: 17px;
    text-align: center;
    margin-top: 1.5%;
  }

  #yt-video {
    width: 100%;
    height: 210px !important;
    margin: 25px auto;
  }
  .border-container-1 {
    height: 500px !important;
  }
  .border-container-2 {
    height: 550px !important;
  }
  .border-container-tylenovo-2 {
    height: 670px !important;
  }
}
@media screen and (max-width: 1200px) {
  .title-sessions-2 {
    font-size: 23px;
  }
}
@media screen and (max-width: 1125px) {
  .title-sessions-2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 725px) {
  .title-sessions-2 {
    font-size: 15px;
  }
}
@media screen and (max-width: 539px) {
  .title-sessions-2 {
    font-size: 13px;
    text-align: center;
  }
}
@media screen and (max-width: 420px) {
  #yt-video {
    width: 100%;
    height: 190px !important;
  }
}

@media screen and (max-width: 400px) {
  .title-sessions-2 {
    font-size: 13px;
    text-align: center;
  }
  #yt-video {
    width: 100%;
    height: 180px !important;
  }
}

@media screen and (max-width: 380px) {
  .title-sessions-2 {
    font-size: 14px;
    text-align: center;
    white-space: pre-line;
  }
  .label-address,
  .address {
    font-size: 16px;
  }
  #yt-video {
    width: 100%;
    height: 165px !important;
  }
}

@media screen and (max-width: 768px) {
  .card-session {
    margin-top: 2.5% !important;
    margin-bottom: 3% !important;
    height: 450px !important;
  }
  article {
    font-size: 16px;
  }

  article h2 {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1250px) {
  .boxes-wrap {
    display: flex;
    justify-content: space-around;
  }
}


@media screen and (max-width: 290px) {
  .card-session {
    margin-top: 2.5% !important;
    margin-bottom: 3% !important;
    height: 550px !important;
  }

  article {
    font-size: 12px;
  }

  article h2 {
    font-size: 1.6rem;
  }
}
