.contact-page-wrap {
  min-height: 100vh;
}
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
  margin-bottom: -30px;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 30px;
}
.contacts-icon {
  margin-left: 5px;
}
.contact-template {
  display: flex;
  align-items: center;
  border: 1px solid #ea1538;
  border-radius: 10px;
  padding: 10px;
}

.contact-icon {
  width: 40px;
  height: 40px;
  background-color: #ea1538;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-details {
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 18px;
  margin-bottom: 5px;
  text-decoration: none !important;
}

p {
  text-decoration: none !important;
  margin: 0;
}

@media screen and (max-width: 690px) {
  .contact-page-wrap {
    margin-top: 0% !important;
    margin-bottom: -50% !important;
  }
}

@media screen and (max-width: 950px) {
  .contact-page-wrap {
    margin-top: 0% !important;
    margin-bottom: -21% !important;
  }
}

@media screen and (max-width: 800px) {
  .contact-page-wrap {
    margin-top: 0% !important;
    margin-bottom: -36% !important;
  }
}
@media screen and (max-width: 500px) {
  .contact-page-wrap {
    margin-top: 0% !important;
    margin-bottom: -73% !important;
  }
}

